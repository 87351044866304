import React, { Component } from "react";
import AppIndex from "@/biz/setting/setting.com";

export default class SettingRouter extends Component {
  render() {
    return (
      <div>
        <AppIndex />
      </div>
    );
  }
}

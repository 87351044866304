/*
 * @Author: HanRui(JoyNop)
 * @Date: 2021-02-05 15:17:08
 * @LastEditors: HanRui(JoyNop)
 * @LastEditTime: 2021-11-18 20:31:33
 * @Description: file content
 * @FilePath: /pms-cpanel/src/biz/setting/setting.com.tsx
 */
import React, { Component, ReactNode } from "react";
import OrgTemplate from "./orgTemplate/orgTemplate.com";
import Application from "./application/application.com";
import CombineSetting from "./combineSetting.com";
import Operation from "./operation/operation.com";
import { PageHeader, Tabs } from "antd";
import style from "./appPanel.module.less";
import Permission from "./permission/permission.com";
import RoleTemplate from "./roleTemplate/roleTemplate.com";
import axios from "@/utils/http";
import {
  ApartmentOutlined,
  FolderViewOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
const { TabPane } = Tabs;

export default class Setting extends Component {
  public render(): ReactNode {
    return (
      <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
        <TabPane
          tab={
            <span>
              <ApartmentOutlined />
              组织模板
            </span>
          }
          key="1"
        >
          <OrgTemplate />
        </TabPane>
        <TabPane
          tab={
            <span>
              <SmileOutlined />
              角色模板
            </span>
          }
          key="2"
        >
          <RoleTemplate />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FolderViewOutlined />
              应用权限
            </span>
          }
          key="3"
        >
          <CombineSetting />
        </TabPane>
        <TabPane
          tab={
            <span>
              <UserOutlined />
              运营人员
            </span>
          }
          key="4"
        >
          <Operation />
        </TabPane>
      </Tabs>
    );
  }
}

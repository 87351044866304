import React, { Component } from "react";
import Login from "../biz/login/login.com";

export default class LoginRouter extends Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}

import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Checkbox,
  Space,
} from "antd";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";
import { Typography } from "antd";
const { Text } = Typography;

interface IProps {
  app: any;
  update: any;
}

interface IState {
  visibleEdit: boolean; // 编辑应用
  visiblePermission: boolean; // 应用权限
  systemAllPermissionCheckbox: any[]; //权限列表
}

export default class AppTemplateCard extends Component<IProps, IState> {
  private formEditRef = React.createRef<FormInstance>();

  public state: IState = {
    visibleEdit: false,
    visiblePermission: false,
    systemAllPermissionCheckbox: [],
  };

  /**
   * @desc 打开权限弹窗
   */
  public showPermissionModal = () => {
    this.setState({
      visiblePermission: true,
    });
  };

  /**
   * @desc 关闭权限弹窗
   */
  public handlePermissionOk = () => {
    this.setState({
      visiblePermission: false,
    });
  };

  /**
   * @desc 关闭权限弹窗
   */
  public handlePermissionCancel = () => {
    this.setState({
      visiblePermission: false,
    });
  };

  /**
   * @desc 打开编辑弹窗
   */
  public openEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  /**
   * @desc 关闭编辑弹窗
   */
  public closeEdit = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  /**
   * @desc 修改应用提交
   */
  public onEditSubmit = async (appId: string, values: any) => {
    console.log(values);
    try {
      const res = await axios.put(
        `/api/account/v1/app/system/app/${appId}`,
        values
      );
      console.log("修改应用成功", res.data.data);
      // 关闭弹窗
      this.setState({
        visibleEdit: false,
      });
      // 获取一遍
      this.props.update();
    } catch (error) {
      console.log("修改应用失败", error.response);
      throw new Error("失败");
    }
  };

  /**
   * @desc 修改应用初始化
   */
  public onEditAppTemplateInit = async (appId: string) => {
    try {
      const res = await axios.get(`/api/account/v1/app/${appId}/permission`);
      this.formEditRef.current?.setFieldsValue({
        code: res.data.data.code,
        name: res.data.data.name,
        type: res.data.data.type,
        router: res.data.data.router,
        icon: res.data.data.icon,
      });
      this.openEdit();
      console.log("删除应用成功", res.data.data);
    } catch (error) {
      console.log("删除组织模板错误", error.response);
    }
  };

  /**
   * @desc 删除应用
   */
  public onDeleteAppTemplate = async (appId: string) => {
    try {
      const res = await axios.delete(`/api/account/v1/app/system/app/${appId}`);
      this.props.update();
      console.log("删除应用成功", res.data.data);
    } catch (error) {
      console.log("删除应用错误", error.response);
    }
  };

  /**
   * @desc 打开应用权限弹窗初始化
   */
  public onManagePermission = () => {
    this.showPermissionModal();
    this.onGetAllPermissionLists();
  };

  /**
   * @desc 获取所有权限
   */
  public onGetAllPermissionLists = async () => {
    try {
      const res = await axios.get("/api/account/v1/permission/system/all");
      console.log("所有权限成功", res.data.data);
      const data = res.data.data.map((res: any) => {
        return {
          id: res.id,
          code: res.code,
          name: res.name,
        };
      });
      console.log("data", data);
      this.setState({
        systemAllPermissionCheckbox: data,
      });
    } catch (error) {
      console.log("所有权限错误", error.response);
    }
  };

  /**
   * @desc 点击按钮添加/删除应用
   */
  public onChange = (e: any, appId: any, permissionId: any) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked === false) {
      this.onRemovePermission(appId, permissionId);
    } else {
      this.onAddPermission(appId, permissionId);
    }
  };

  /**
   * @desc 删除权限
   */
  public onRemovePermission = async (appId: string, permissionId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/app/system/app/${appId}/permission/${permissionId}`
      );
      this.props.update();
      console.log("移除权限成功", res.data.data);
    } catch (error) {
      console.log("移除权限错误", error.response);
    }
  };

  /**
   * @desc 添加权限
   */
  public onAddPermission = async (appId: string, permissionId: string) => {
    try {
      const res = await axios.post(
        `/api/account/v1/app/system/app/${appId}/permission/${permissionId}`
      );
      this.props.update();
      console.log("添加权限成功", res.data.data);
    } catch (error) {
      console.log("添加权限错误", error.response);
    }
  };

  public componentDidMount() {}
  public render(): ReactNode {
    const { id, code, name, type, router, icon, permissions } = this.props.app;
    return (
      <Card style={{ width: "300px", margin: "16px" }} hoverable>
        {/* <p>id:{id}</p> */}
        <p>
          <Text strong>应用编码: </Text>
          {code}
        </p>
        <p>
          <Text strong>应用名称: </Text>
          {name}
        </p>
        <p>
          <Text strong>应用类型: </Text>
          {type === 0 && "组织管理"}
          {type === 1 && "专业应用"}
          {type === 2 && "通用应用"}
        </p>

        <p>
          <Text strong>应用路由: </Text>
          {router}
        </p>
        <p
          style={{
            width: "250px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          title={icon}
        >
          <Text strong>应用图标: </Text>
          {icon}
        </p>

        {/* <p>-------------------</p>
        {permissions.map((res: any, index: any) => (
          <div key={res.id}>
            <p>id:{res.id}</p>
            <p>code:{res.code}</p>
            <p>name:{res.name}</p>
          </div>
        ))} */}
        <Space size="middle">
          <Button type="link" onClick={() => this.onEditAppTemplateInit(id)}>
            修改
          </Button>

          <Button
            type="link"
            onClick={() => this.onManagePermission()}
            style={{ marginRight: "16px" }}
          >
            权限
          </Button>

          <Popconfirm
            title="删除这个应用?"
            onConfirm={() => this.onDeleteAppTemplate(id)}
            okText="确定"
            cancelText="取消"
          >
            <a href="#">删除</a>
          </Popconfirm>
        </Space>
        <Modal
          visible={this.state.visibleEdit}
          title="修改应用"
          okText="提交"
          cancelText="取消"
          onCancel={this.closeEdit}
          forceRender
          onOk={async () => {
            try {
              const values = await this.formEditRef.current?.validateFields();
              console.log(values);
              await this.onEditSubmit(id, values);
              this.formEditRef.current?.resetFields();
              this.props.update();
            } catch (error) {
              console.log("校验失败:", error);
            }
          }}
        >
          <Form ref={this.formEditRef} layout="vertical" name="editApplication">
            <Form.Item
              name="code"
              label="应用编码"
              rules={[
                {
                  required: true,
                  message: "请输入合理的应用编码!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="应用名称"
              rules={[
                {
                  required: true,
                  message: "请输入合理的应用名称!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="type"
              label="应用类型"
              rules={[
                {
                  required: true,
                  message: "请输入合理的应用类型!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={0}>组织管理</Radio>
                <Radio value={1}>专业应用</Radio>
                <Radio value={2}>通用应用</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="router"
              label="应用路由 /:orgId"
              rules={[
                {
                  required: true,
                  message: "请输入合理的应用路由!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="icon"
              label="图标URL"
              rules={[
                {
                  required: true,
                  message: "请输入合理的图标URL!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        {/* 应用权限管理 */}
        <Modal
          title="应用权限管理"
          visible={this.state.visiblePermission}
          onOk={this.handlePermissionOk}
          onCancel={this.handlePermissionCancel}
          forceRender
        >
          {this.state.systemAllPermissionCheckbox.map((res: any) => (
            <p key={res.id}>
              <Checkbox
                onChange={(e) => this.onChange(e, id, res.id)}
                checked={
                  permissions.findIndex((r: any) => r.id === res.id) !== -1
                    ? true
                    : false
                }
              >
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "16px",
                    display: "inline-block",
                  }}
                >
                  <p
                    style={{
                      width: "120px",
                      fontSize: "14px",
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      lineHeight: "14px",
                      padding: 0,
                      margin: 0,
                    }}
                    title={res.name}
                  >
                    {`${res.name}`}
                  </p>
                  <p
                    style={{
                      width: "100px",
                      fontSize: "14px",
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      lineHeight: "14px",
                      padding: 0,
                      margin: 0,
                    }}
                    title={res.code}
                  >
                    {res.code}
                  </p>
                </p>
              </Checkbox>
            </p>
          ))}
        </Modal>
      </Card>
    );
  }
}

import { Route, Redirect, RouteProps } from "react-router-dom";
import * as React from "react";
import { PMSLayout } from "../common";
import { checkAuthlocalStorage, clearLocalStorage } from "./handleAuth";

export interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  permission: boolean;
  single?: boolean;
  isIndex?: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  let {
    component: Component,
    permission: Permission,
    single: Single,
    ...rest
  } = props;
  // checkAuthlocalStorage();
  if (Permission && checkAuthlocalStorage()) {
    return (
      <Route
        {...rest}
        render={(props) =>
          Single ? (
            <Component {...props} />
          ) : (
            <PMSLayout {...props}>
              <Component {...props} />
            </PMSLayout>
          )
        }
      />
    );
  } else {
    clearLocalStorage();
    return <Redirect to="/login" />;
  }
};

import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Typography,
} from "antd";
import ApplicationCard from "./applicationCard.com";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";
const { Title } = Typography;

interface IProps {}
interface IState {
  visible: boolean; // 弹窗开关
  systemAllApplicationLists: any[]; // 应用列表
}

export default class Application extends Component<IProps, IState> {
  private formRef = React.createRef<FormInstance>();
  public state: IState = {
    visible: false,
    systemAllApplicationLists: [],
  };

  /**
   * @desc 创建应用
   */
  public onCreate = async (values: any) => {
    console.log(values);
    try {
      const res = await axios.post("/api/account/v1/app/system", values);
      console.log("创建应用模板成功", res.data.data);
      // 获取一遍
      this.onGetAllApplication();
      this.setState({
        visible: false,
      });
    } catch (error) {
      console.log("创建应用模板失败", error.response);
    }
  };

  /**
   * @desc 打开创建应用弹窗
   */
  public open = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * @desc 关闭创建应用弹窗
   */
  public close = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * @desc 获取所有应用
   */
  public onGetAllApplication = async () => {
    try {
      const res = await axios.get("/api/account/v1/app/system/all");
      console.log("所有应用成功", res.data.data);
      this.setState({
        systemAllApplicationLists: res.data.data,
      });
    } catch (error) {
      console.log("所有应用错误", error.response);
    }
  };

  public componentDidMount() {
    this.onGetAllApplication();
  }
  public render(): ReactNode {
    return (
      <div>
        <div>
          <div style={{ overflow: "hidden" }}>
            <Title level={4} style={{ float: "left" }}>
              应用权限管理
            </Title>
            <Button
              type="primary"
              onClick={this.open}
              style={{ float: "right" }}
            >
              创建应用
            </Button>
          </div>

          <Modal
            visible={this.state.visible}
            title="创建应用"
            okText="提交"
            cancelText="取消"
            onCancel={this.close}
            forceRender
            onOk={async () => {
              try {
                const values = await this.formRef.current?.validateFields();
                console.log(values);
                await this.onCreate(values);
                this.formRef.current?.resetFields();
              } catch (error) {
                console.log("校验失败:", error);
              }
            }}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              name="appTemplate"
              initialValues={{
                type: 0,
              }}
            >
              <Form.Item
                name="code"
                label="应用编码"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的应用编码!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="name"
                label="应用名称"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的应用名称!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="type"
                label="应用类型"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的应用类型!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>组织管理</Radio>
                  <Radio value={1}>专业应用</Radio>
                  <Radio value={2}>通用应用</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="router"
                label="应用路由 /:orgId"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的应用路由!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="icon"
                label="图标URL"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的图标URL!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.systemAllApplicationLists.map((app: any) => (
            <ApplicationCard
              key={app.id}
              app={app}
              update={this.onGetAllApplication}
            />
          ))}
        </div>
      </div>
    );
  }
}

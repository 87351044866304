import React, { Component, ReactNode } from "react";
import OrgTemplate from "./orgTemplate/orgTemplate.com";
import Application from "./application/application.com";
import { PageHeader, Tabs, Space } from "antd";
import { Carousel } from "antd";
import style from "./appPanel.module.less";
import Permission from "./permission/permission.com";
import RoleTemplate from "./roleTemplate/roleTemplate.com";
import axios from "@/utils/http";
import * as Style from "./combineSetting.module.less";
const { TabPane } = Tabs;

export interface IProps {}
export interface IState {
  toggle: boolean; // 切换页面
}

export default class CombineSetting extends Component<IProps, IState> {
  public state: IState = {
    toggle: true,
  };

  public toggleView = (e: KeyboardEvent) => {
    // e.preventDefault();
    // console.log(e.code);
    if (e.code === "ArrowLeft") {
      this.setState({ toggle: true });
    }
    if (e.code === "ArrowRight") {
      this.setState({ toggle: false });
    }
  };

  public componentDidMount() {
    // document.addEventListener("keydown", this.toggleView);
  }

  public componentWillUnmount() {
    // document.removeEventListener("keydown", this.toggleView);
  }

  public render(): ReactNode {
    return (
      <div style={{ padding: "16px" }} id="toggle1">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              cursor: "pointer",
              height: "50px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "8px",
            }}
            onClick={() => this.setState({ toggle: true })}
          >
            <button
              className={
                this.state.toggle === false
                  ? Style.blue_button
                  : Style.blue_button_active
              }
            >
              2
            </button>
          </div>
          <div
            style={{
              cursor: "pointer",
              height: "50px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => this.setState({ toggle: false })}
          >
            <button
              className={
                this.state.toggle === true
                  ? Style.blue_button
                  : Style.blue_button_active
              }
            >
              2
            </button>
          </div>
        </div>

        {this.state.toggle === true ? (
          <div>
            <Application />
          </div>
        ) : (
          <div>
            <Permission />
          </div>
        )}
      </div>
    );
  }
}

/*
 * @Author: HanRUi
 * @Date: 2020-04-01 12:00:24
 * @LastEditTime: 2020-04-23 17:23:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \PrideBrowser\src\utils\http.ts
 */
import axios from "axios";
import { message } from "antd";
import { loginOutForced } from "./handleAuth";

let url = process.env.REACT_APP_BUILD_ENV;
if (url !== "production") {
  url = "development";
}

axios.defaults.baseURL = "/" + url;
axios.defaults.withCredentials = true;

// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // console.log(config);
    let reqUrl = config.url;
    // debugger;
    if (!reqUrl?.includes("api/account")) {
      const urlConfig = reqUrl?.split("/");
      if (urlConfig) {
        urlConfig.splice(2, 0, "server");
      }
      reqUrl = urlConfig?.join("/");
      config.url = reqUrl;
    }
    // 加载
    return config;
  },
  (error) => {
    console.log("reqError==>", error);
    message.error(error.toString());
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 错误提醒
    // console.log(error.response);
    const res = error.response;

    if (res.status === 401) {
      loginOutForced();
    }

    try {
      let messageStr = "";
      if (error.response.data.hasOwnProperty("message")) {
        console.log("resError(GetMessage)==>", error.response);
        messageStr = `错误:${error.response.data.message}`;
      } else {
        messageStr = `系统错误:${error.response.status},${error.response.statusText}`;
        console.log("resError(NotFoundMessage)==>", error.response);
      }
      message.error(messageStr);
      return Promise.reject(error);
    } catch (catchError) {
      console.log("resCatchError==>", catchError);
      const messageStr = `ErrorStatusCode:${catchError.response.status},${catchError.response.statusText}`;
      message.error(messageStr);
    }
  }
);

export default axios;

import React, { Component } from "react";
import axios from "../../utils/http";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Form, Input, Button, Select, Typography, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import * as Style from "./login.module.less";
import { Redirect } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
const { Option } = Select;
const { Title } = Typography;

interface Props extends RouteComponentProps {}

interface State {}

class Login extends Component<Props, State> {
  phoneLoginRef = React.createRef<FormInstance>();
  passwordLoginRef = React.createRef<FormInstance>();

  state = {
    isAuth: false,
    toggleLogin: "phone",
    btnText: "获取验证码",
    seconds: 60, //称数初始化
    liked: true, //获取验证码文案
  };

  // 倒计时
  sendCode = () => {
    let siv = setInterval(() => {
      this.setState(
        {
          liked: false,
          seconds: this.state.seconds - 1,
        },
        () => {
          if (this.state.seconds <= 0) {
            clearInterval(siv);
            this.setState({
              liked: true,
              secounds: 60,
            });
          }
        }
      );
    }, 1000);
  };

  // 用户名密码登录
  onPasswordLogin = async (values: any) => {
    try {
      const res = await axios.post(
        "/api/account/v1/sign/signin/username",
        values
      );
      console.log("账户密码登录成功", res.data.data);
      if (res.data.data.activate === false) {
        this.props.history.push("/login");
        message.error("登录失败!当前帐号无法登录后台系统！");
        throw new Error("登录失败!当前帐号无法登录后台系统！");
      } else {
        localStorage.setItem("auth", JSON.stringify(true));
        this.setState({ isAuth: true });
      }
    } catch (error) {
      console.log("账户密码登录错误", error.response);
    }
  };

  /**
   * @desc  手机登录表单发送验证码
   */
  onPhoneLoginReceiveCaptcha = async () => {
    try {
      // 判断手机号码
      const errorInfo = await this.phoneLoginRef.current?.validateFields([
        "phone",
      ]);
      console.log("errorInfo", errorInfo);
      if (errorInfo?.errorFields?.length > 0) {
        throw new Error();
      }
      const phone = this.phoneLoginRef.current?.getFieldValue("phone");
      const res: any = await axios.get(
        `/api/account/v1/sign/captcha/phone/${phone}`
      );
      this.sendCode();
      console.log("手机登录表单发送验证码成功", res.data.data);
    } catch (error) {
      console.log("手机登录表单发送验证码失败", error.response);
    }
  };

  /**
   * @desc  手机登录表单提交
   */
  onPhoneLoginSubmit = async (values: any) => {
    try {
      const res = await axios.post("/api/account/v1/sign/signin/phone", values);
      console.log("手机登录表单成功", res.data);
      if (res.data.data.registered === false) {
        this.props.history.push("/login");
        message.error("登录失败!当前帐号无法登录后台系统！");
        throw new Error("登录失败!当前帐号无法登录后台系统！");
      } else {
        localStorage.setItem("auth", JSON.stringify(true));
        this.setState({ isAuth: true });
      }
    } catch (error) {
      // message.error("登录失败!当前帐号无法登录后台系统！");
      console.log("手机登录表单错误", error.response);
    }
  };

  /**
   * @desc 跳到账户密码登录界面
   */
  onToggleLoginPassword = () => {
    this.setState({
      toggleLogin: "password",
    });
  };

  /**
   * @desc 跳到手机验证码登陆界面
   */
  onToggleLoginPhone = () => {
    this.setState({
      toggleLogin: "phone",
    });
  };

  render() {
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <Select style={{ width: 70 }} defaultValue="86" disabled>
          <Option value="86">+86</Option>
        </Select>
      </Form.Item>
    );

    // 手机登录
    const phoneLogin = (
      <div>
        <Title>使用手机号码登录</Title>
        <Form
          name="phoneLogin"
          ref={this.phoneLoginRef}
          initialValues={{}}
          onFinish={this.onPhoneLoginSubmit}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "请输入正确的手机号!",
                // pattern: /^1[34578]\d{9}$/,
              },
            ]}
          >
            <Input
              addonBefore={prefixSelector}
              placeholder="手机号"
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item>
            <Input.Group compact>
              <Form.Item
                name="captcha"
                noStyle
                rules={[{ required: true, message: "请输入正确的验证码!" }]}
              >
                <Input
                  placeholder="验证码"
                  style={{ width: "300px" }}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  style={{ width: "100px" }}
                  onClick={this.onPhoneLoginReceiveCaptcha}
                  disabled={!this.state.liked}
                >
                  {this.state.liked ? (
                    <span>{this.state.btnText}</span>
                  ) : (
                    <span>{this.state.seconds + "秒后发送"}</span>
                  )}
                </Button>
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              提交
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="link" onClick={this.onToggleLoginPassword}>
              用户名/密码登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    );

    // 密码登录
    const passwordLogin = (
      <div>
        <Title>使用用户名密码登录</Title>
        <Form
          name="passwordLogin"
          ref={this.passwordLoginRef}
          initialValues={{}}
          onFinish={this.onPasswordLogin}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "请输入正确的用户名!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="用户名"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "请输入正确的密码!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="密码"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              提交
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="link" onClick={this.onToggleLoginPhone}>
              手机登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    );

    // 默认登录页面
    let view = phoneLogin;

    // 手机登录界面
    if (this.state.toggleLogin === "phone") {
      view = phoneLogin;
    }

    // 密码登录界面
    if (this.state.toggleLogin === "password") {
      view = passwordLogin;
    }

    // 登录之后
    if (this.state.isAuth === true) {
      return <Redirect to={`/my`} push />;
    }

    return (
      <div className={Style.page}>
        <div>
          <Row
            justify="center"
            align="middle"
            style={{
              height: "100vh",
            }}
            className=""
          >
            <Col style={{ flex: "0 0 400px" }}>{view}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);

import React from "react";
import { Layout, Menu, Button, Tooltip, Typography, Modal } from "antd";
import Style from "./pmsLayout.module.less";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  SettingOutlined,
  PoweroffOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import PMSRightTool from "./pmsRightTool.com";
import axios from "@/utils/http";
const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { confirm } = Modal;
interface IPMSLayoutProps {
  children?: any;
  match?: any;
  history?: any;
}
/**
 *PMS整体框架布局
 *
 * @export
 * @class PMSLayout
 * @extends {React.Component<IPMSLayoutProps>}
 */
export class PMSLayout extends React.Component<IPMSLayoutProps> {
  public state = {
    collapsed: true,
    nickName: "",
  };
  private onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  async componentDidMount() {
    const myInfo = await axios.get(`/api/account/v1/user/my/info`);
    console.log("myInfo.data.data", myInfo.data.data.nickname);
    this.setState({
      nickName: myInfo.data.data.nickname,
    });
  }

  /**
   * @desc 添加权限
   */
  public quit = async () => {
    try {
      const res = await axios.delete("/api/account/v1/sign/signout");
      console.log("退出成功", res.data.data);
      window.location.href = "/login";
    } catch (error) {
      console.log("退出错误", error.response);
    }
  };

  render() {
    const showConfirm = () => {
      const that = this;
      confirm({
        title: "你要退出 PMS控制面板 吗?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        onOk() {
          that.quit();
        },
        onCancel() {},
      });
    };

    return (
      <Layout className={Style.layout_paper}>
        <Header className={Style.layout_header}>
          <div>
            {/* <div className={Style.layout_logo}></div> */}
            <Title
              level={3}
              style={{
                color: "#fff",
                lineHeight: "50px",
                paddingTop: "14px",
              }}
            >
              PMS 控制面板
            </Title>
          </div>

          {/* 右侧功能菜单 */}
          <div>
            <span style={{ color: "#fff", marginRight: "16px" }}>
              欢迎，{this.state.nickName}{" "}
            </span>
            {/* <PMSRightTool /> */}
            <Button
              type="primary"
              icon={<PoweroffOutlined />}
              onClick={showConfirm}
            />
          </div>
        </Header>
        {/* 主体内容 */}
        <Layout>
          {/* 主体左侧菜单 */}
          <Sider
            theme="light"
            // collapsible
            className={Style.layout_sider}
            collapsed={this.state.collapsed}
            // onCollapse={this.onCollapse}
          >
            <div className={Style.layout_sider_container}>
              <Menu
                key={1}
                inlineIndent={1}
                theme="light"
                defaultSelectedKeys={["1"]}
                mode="inline"
                style={{
                  width: 65,
                  height: "90vh",
                  overflowY: "hidden",
                  overflowX: "hidden",
                }}
              >
                <Menu.Item key="1">
                  <Link to={`/my`}>
                    <UserOutlined />
                    <span>我的</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="2">
                  <Link to={`/setting`}>
                    <SettingOutlined />
                    <span>权限设置</span>
                  </Link>
                </Menu.Item>
              </Menu>
              {/* <div style={{ width: 65, textAlign: "center" }}>
                <Tooltip title="设置">
                  <Link to={`/`}>
                    <Button shape="circle" icon={<SettingOutlined />} />
                  </Link>
                </Tooltip>
              </div> */}
            </div>
          </Sider>
          {/* 主体正文 */}
          <Layout style={{ padding: "8px" }}>
            <Content className={Style.main_content}>
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

import React from "react";
import PMSLicense from "@/biz/my/my.com";

export default function MyRouter() {
  return (
    <div>
      <PMSLicense />
    </div>
  );
}

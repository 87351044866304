import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Typography,
  Space,
} from "antd";
import { Row, Col } from "antd";
import { Avatar } from "antd";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";
const { Title, Text } = Typography;
const { Search } = Input;
export interface IProps {}

export interface IState {
  visible: boolean; // 弹窗开关
  systemAllOperationLists: any[]; //所有运维人员
  systemSearchOperation: any[]; //搜索运维人员
  loading: boolean; // 载入
}

export default class Operation extends Component<IProps, IState> {
  private formRef = React.createRef<FormInstance>();

  public state: IState = {
    visible: false,
    systemAllOperationLists: [],
    systemSearchOperation: [],
    loading: false,
  };

  /**
   * @desc 创建运维人员
   */
  public onCreate = async (values: any) => {
    console.log(values);

    try {
      const res = await axios.post(
        "/api/account/v1/role/system/user/operation-role",
        values
      );
      console.log("创建运维人员成功", res.data.data);
      // 获取一遍
      this.onGetAllOperation();
      this.setState({
        visible: false,
      });
    } catch (error) {
      console.log("创建运维人员失败", error.response);
      throw new Error("创建运维人员失败");
    }
  };

  /**
   * @desc 打开创建运维人员弹窗
   */
  public open = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * @desc 关闭创建运维人员弹窗
   */
  public close = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * @desc 获取所有运维人员
   */
  public onGetAllOperation = async () => {
    try {
      const res = await axios.get("/api/account/v1/role/system/operation/user");
      console.log("所有运维人员成功", res.data.data);
      this.setState({
        systemAllOperationLists: res.data.data,
      });
    } catch (error) {
      console.log("所有运维人员错误", error.response);
    }
  };

  /**
   * @desc 删除单个运维人员
   */
  public onRemoveOperation = async (userId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/role/system/user/${userId}/operation-role`
      );
      this.onGetAllOperation();
      console.log("移除单个运维人员成功", res.data.data);
    } catch (error) {
      console.log("移除单个运维人员错误", error.response);
    }
  };

  /**
   * @desc 搜索运维人员
   */
  public onSearchOperation = async (usernameLike: string) => {
    try {
      if (!usernameLike) {
        message.error("输入不合法!");
        throw new Error("输入不合法!");
      }
      this.setState({
        loading: true,
      });
      const res = await axios.get(
        `/api/account/v1/user/usernamelike/${usernameLike}?usernameLike=${usernameLike}`
      );
      console.log("搜索运维人员成功", res.data.data);
      this.setState(
        {
          systemSearchOperation: res.data.data,
          loading: false,
        },
        () => {
          if (this.state.systemSearchOperation.length === 0) {
            message.error("没有搜到相关人员!");
          }
        }
      );
      console.log(res.data);
    } catch (error) {
      console.log("搜索运维人员错误", error, error.response);
    }
  };

  public componentDidMount() {
    this.onGetAllOperation();
  }

  public render(): ReactNode {
    return (
      <div>
        <div>
          <div style={{ overflow: "hidden" }}>
            <Button
              type="primary"
              onClick={this.open}
              style={{ float: "right" }}
            >
              创建运维人员
            </Button>
          </div>

          <Modal
            visible={this.state.visible}
            title="创建运维人员"
            okText="提交"
            cancelText="取消"
            onCancel={this.close}
            style={{ width: "600px" }}
            forceRender
            onOk={async () => {
              try {
                const values = await this.formRef.current?.validateFields();
                console.log(values);
                await this.onCreate(values);
                this.formRef.current?.resetFields();
              } catch (error) {
                console.log("校验失败:", error);
              }
            }}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              name="appTemplate"
              initialValues={{
                type: 0,
              }}
            >
              <Search
                placeholder="输入人员账户"
                enterButton="搜索"
                size="large"
                onSearch={this.onSearchOperation}
                loading={this.state.loading}
              />
              <div style={{ height: "16px" }}></div>
              <Form.Item
                name="userId"
                label="人员"
                rules={[{ required: true, message: "请选择合理的人员!" }]}
              >
                <Radio.Group>
                  <Row justify="space-between">
                    {this.state.systemSearchOperation.map((res: any) => (
                      <Col span={24}>
                        <Radio
                          value={res.id}
                          key={res.id}
                          style={{ display: "flex" }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              lineHeight: "16px",
                            }}
                          >
                            <Text strong>账户: </Text>
                            <p
                              style={{
                                width: "120px",
                                fontSize: "14px",
                                display: "inline-block",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                lineHeight: "15px",
                                padding: 0,
                                margin: 0,
                              }}
                              title={res.username}
                            >
                              {res.username}
                            </p>
                            <Text strong>昵称: </Text>
                            <p
                              style={{
                                width: "100px",
                                fontSize: "14px",
                                display: "inline-block",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                lineHeight: "14px",
                                padding: 0,
                                margin: 0,
                              }}
                              title={res.nickname}
                            >
                              {res.nickname}
                            </p>
                            <Text strong>电话: </Text>
                            <p
                              style={{
                                width: "100px",
                                fontSize: "14px",
                                display: "inline-block",
                                lineHeight: "14px",
                                padding: 0,
                                margin: 0,
                              }}
                              title={res.phone}
                            >
                              {res.phone}
                            </p>
                          </p>

                          {/* <Card hoverable>
                            <p>账户: {res.username}</p>
                            <p>昵称: {res.nickname}</p>
                            <p>电话: {res.phone}</p>

                            <p>账户: {res.username} - 昵称: {res.nickname} - 电话: {res.phone}</p>
                          </Card> */}
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>

              {/* <Form.Item
                name="userId"
                label="人员ID"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的人员ID!",
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
            </Form>
          </Modal>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.systemAllOperationLists.map((app: any) => (
            <Card
              style={{ width: "200px", margin: "16px" }}
              key={app.id}
              hoverable
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "16px",
                }}
              >
                <Avatar size={64} src={app.avatar} />
              </div>
              <p>
                <Text strong>账户: </Text>
                {app.username}
              </p>
              <p
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                title={app.nickname}
              >
                <Text strong>昵称: </Text>
                {app.nickname}
              </p>
              <p>
                <Text strong>电话: </Text>
                {app.phone}
              </p>
              <Space size="middle">
                <Popconfirm
                  title="移除这个运营人员?"
                  onConfirm={() => this.onRemoveOperation(app.id)}
                  okText="确定"
                  cancelText="取消"
                >
                  <a href="#">移除</a>
                </Popconfirm>
              </Space>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

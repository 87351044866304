import axios from "./http";

/*
 * @Author: HanRui
 */
export const checkAuthlocalStorage = () => {
  let isAuth: boolean = false;
  try {
    const auth: any = JSON.parse(localStorage.getItem("auth") as any);
    if (auth == true) {
      isAuth = true;
    }
    console.log("Auth", auth, isAuth);

    // return isAuth;
  } catch (error) {
    console.log("isAuth", isAuth);
    // return isAuth;
  } finally {
    return isAuth;
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem("auth");
};
/**
 *
 * @description 强制退出
 */
export const loginOutForced = async () => {
  try {
    clearLocalStorage();
    await axios.delete(`/api/account/v1/sign/signout`); //退出登录
  } catch (error) {
  } finally {
    window.location.href = "/login"; //跳转
  }
};

//检查状态并退出

export const checkAuth = () => {
  //检查
  if (!checkAuthlocalStorage()) {
    //失败并退出
    loginOutForced();
  }
};

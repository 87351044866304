import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
} from "antd";
import OrgTemplateCard from "./orgTemplateCard.com";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";

interface IProps {}
interface IState {
  visible: boolean; // 弹窗开关
  systemAllOrgTemplateLists: any[]; //所有组织模板
}

export default class OrgTemplate extends Component<IProps, IState> {
  private formRef = React.createRef<FormInstance>();

  public state: IState = {
    visible: false,
    systemAllOrgTemplateLists: [],
  };

  /**
   * @desc 创建组织模板
   */
  public onCreate = async (values: any) => {
    try {
      const res = await axios.post(
        "/api/account/v1/org-template/system",
        values
      );
      console.log("创建组织模板成功", res.data.data);
      // 获取一遍
      this.onGetAllOrgTemplate();
      this.setState({
        visible: false,
      });
    } catch (error) {
      console.log("创建组织模板失败", error.response);
    }
  };

  /**
   * @desc 打开创建弹窗
   */
  public open = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * @desc 关闭创建弹窗
   */
  public close = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * @desc 获取组织模板列表
   */
  public onGetAllOrgTemplate = async () => {
    try {
      const res = await axios.get("/api/account/v1/org-template/system");
      console.log("所有组织模板成功", res.data.data);
      this.setState({
        systemAllOrgTemplateLists: res.data.data,
      });
    } catch (error) {
      console.log("所有公司列表错误", error.response);
    }
  };

  public componentDidMount() {
    this.onGetAllOrgTemplate();
  }
  public render(): ReactNode {
    return (
      <div>
        <div>
          <div style={{ overflow: "hidden" }}>
            <Button
              type="primary"
              onClick={this.open}
              style={{ float: "right" }}
            >
              创建组织模板
            </Button>
          </div>

          <Modal
            visible={this.state.visible}
            title="创建组织模板"
            okText="提交"
            cancelText="取消"
            onCancel={this.close}
            forceRender
            onOk={() => {
              // 官网例子不好
              this.formRef.current
                ?.validateFields()
                .then((values: any) => {
                  this.onCreate(values);
                  this.formRef.current?.resetFields();
                })
                .catch((info: any) => {
                  console.log("校验失败:", info);
                });
            }}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              name="orgTemplate"
              initialValues={{
                type: 0,
                style: 0,
              }}
            >
              <Form.Item
                name="name"
                label="组织模板名称"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的组织模板名称!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="discription"
                label="描述"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的描述!",
                  },
                ]}
              >
                <Input type="textarea" />
              </Form.Item>
              <Form.Item
                name="type"
                label="类型"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的类型!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>公司</Radio>
                  <Radio value={1}>项目</Radio>
                </Radio.Group>
              </Form.Item>

              {/* <Form.Item
                name="style"
                label="style"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的style!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>0</Radio>
                  <Radio value={1}>1</Radio>
                </Radio.Group>
              </Form.Item> */}
            </Form>
          </Modal>
        </div>
        <div style={{ display: "flex" }}>
          {this.state.systemAllOrgTemplateLists.map((template: any) => (
            <OrgTemplateCard
              key={template.id}
              template={template}
              update={this.onGetAllOrgTemplate}
            />
          ))}
        </div>
      </div>
    );
  }
}

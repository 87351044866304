import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Checkbox,
  Space,
} from "antd";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";
import { Typography } from "antd";
const { Text } = Typography;
interface IProps {
  template: any; // 组件数组
  update: () => void; // 更新父组件
}
interface IState {
  visibleApplication: boolean; // 管理应用
  visibleEdit: boolean; // 修改模板
  systemAllApplicationLists: any[]; // 应用列表
  apps: any[]; // 匹配应用列表
}

export default class OrgTemplateCard extends Component<IProps, IState> {
  private formEditRef = React.createRef<FormInstance>();
  public state: IState = {
    visibleApplication: false,
    visibleEdit: false,
    systemAllApplicationLists: [],
    apps: [],
  };

  /**
   * @desc 打开创建应用弹窗
   */
  public showApplicationModal = () => {
    this.setState({
      visibleApplication: true,
    });
  };

  /**
   * @desc 关闭创建应用弹窗
   */
  public handleApplicationOk = () => {
    this.setState({
      visibleApplication: false,
    });
  };

  /**
   * @desc 关闭创建应用弹窗
   */
  public handleApplicationCancel = () => {
    this.setState({
      visibleApplication: false,
    });
  };

  /**
   * @desc 打开编辑弹窗
   */
  public openEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  /**
   * @desc 关闭编辑弹窗
   */
  public closeEdit = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  /**
   * @desc 修改组织模板
   */
  public onEdit = async (templateId: string, values: any) => {
    console.log(values);

    try {
      const res = await axios.put(
        `/api/account/v1/org-template/system/${templateId}`,
        values
      );
      console.log("修改组织模板成功", res.data.data);
      // 关闭弹窗
      this.setState({
        visibleEdit: false,
      });
      // 获取一遍
      this.props.update();
    } catch (error) {
      console.log("修改组织模板失败", error.response);
      throw new Error("修改组织模板失败");
    }
  };

  /**
   * @desc 初始化获取组织模板内容
   */
  public onEditOrgTemplate = async (templateId: string) => {
    try {
      const res = await axios.get(
        `/api/account/v1/org-template/system/${templateId}`
      );
      this.formEditRef.current?.setFieldsValue({
        name: res.data.data.name,
        discription: res.data.data.discription,
        type: res.data.data.type,
        style: res.data.data.style,
      });
      this.openEdit();
      console.log("删除组织模板成功", res.data.data);
    } catch (error) {
      console.log("删除组织模板错误", error.response);
    }
  };

  /**
   * @desc 删除组织模板
   */
  public onDeleteTemplate = async (templateId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/org-template/system/${templateId}`
      );
      this.props.update();
      console.log("删除组织模板成功", res.data.data);
    } catch (error) {
      console.log("删除组织模板错误", error.response);
    }
  };

  private onUpdateTemplate = async (templateId: string) => {
    try {
      const res = await axios.put(
        `/api/account/v1/role/system/role-template/${templateId}/role/permission`
      );
      // this.props.update();
      message.success("更新成功");
    } catch (error) {
      message.error("更新失败！");
    }
  };
  /**
   * @desc 打开弹窗初始化
   */
  public onManageApplication = (id: string) => {
    this.showApplicationModal();
    this.onGetAllApplicationLists();
    this.onGetAllApplication();
  };

  /**
   * @desc 获取应用列表
   */
  public onGetAllApplicationLists = async () => {
    try {
      const res = await axios.get("/api/account/v1/app/system/all");
      console.log("所有应用成功", res.data.data);
      const data = res.data.data.map((res: any) => {
        return {
          id: res.id,
          code: res.code,
          name: res.name,
          type: res.type,
          router: res.router,
        };
      });
      console.log("data", data);
      this.setState({
        systemAllApplicationLists: data,
      });
    } catch (error) {
      console.log("所有应用错误", error.response);
    }
  };

  /**
   * @desc 点击按钮添加/删除应用
   */
  public onChange = (e: any, templateId: string, appId: string) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked === false) {
      this.onRemoveApplication(templateId, appId);
    } else {
      this.onAddApplication(templateId, appId);
    }
  };

  /**
   * @desc 删除应用
   */
  public onRemoveApplication = async (templateId: string, appId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/org-template/system/${templateId}/app/${appId}`
      );
      await this.onGetAllApplication();
      await this.props.update();
      console.log("移除应用成功", res.data.data);
    } catch (error) {
      console.log("移除应用错误", error.response);
    }
  };

  /**
   * @desc 添加应用
   */
  public onAddApplication = async (templateId: string, appId: string) => {
    const values = { templateId, appId };
    try {
      const res = await axios.post(
        `/api/account/v1/org-template/system/app`,
        values
      );
      await this.onGetAllApplication();
      await this.props.update();
      console.log("添加应用成功", res.data.data);
    } catch (error) {
      console.log("添加应用错误", error.response);
    }
  };

  /**
   * @desc 重新获取所有应用刷新匹配列表
   */
  public onGetAllApplication = async () => {
    const { id: templateId } = this.props.template;

    try {
      const res = await axios.get(
        `/api/account/v1/org-template/system/${templateId}`
      );
      console.log("所有应用成功onGetAllApplication", res.data.data);

      this.setState({
        apps: res.data.data.apps,
      });
    } catch (error) {
      console.log("所有应用错误onGetAllApplication", error.response);
    }
  };

  public componentDidMount() {}

  public render(): ReactNode {
    const { template } = this.props;
    const { id } = this.props.template;
    const { apps } = this.state;
    return (
      <div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Card
            style={{ width: "300px", margin: "16px" }}
            key={template.id}
            hoverable
          >
            {/* <p>id:{template.id}</p> */}
            <p>
              <Text strong>模板名称: </Text>
              {template.name}
            </p>
            <p>
              <Text strong>模板描述: </Text>
              {template.discription}
            </p>
            {template.type === 0 && (
              <p>
                <Text strong>模板类型: </Text>公司
              </p>
            )}
            {template.type === 1 && (
              <p>
                <Text strong>模板类型: </Text>项目
              </p>
            )}
            {/* <p>
              <Text strong>模板Style: </Text>
              {template.style}
            </p> */}
            {/* <p>{template.createAt}</p> */}
            <Space>
              <Button
                type="link"
                onClick={() => this.onEditOrgTemplate(template.id)}
              >
                修改
              </Button>

              <Button
                type="link"
                onClick={() => this.onManageApplication(id)}
                style={{ marginRight: "16px" }}
              >
                应用
              </Button>

              <Popconfirm
                title="删除这个组织模板?"
                onConfirm={() => this.onDeleteTemplate(template.id)}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link">删除</Button>
              </Popconfirm>
              <Popconfirm
                title="确定要更新吗?"
                onConfirm={() => this.onUpdateTemplate(template.id)}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link">更新</Button>
              </Popconfirm>
            </Space>
            {/* 修改组织模板 */}
            <Modal
              visible={this.state.visibleEdit}
              title="修改组织模板"
              okText="提交"
              cancelText="取消"
              onCancel={this.closeEdit}
              forceRender
              onOk={async () => {
                try {
                  const values =
                    await this.formEditRef.current?.validateFields();
                  console.log(values);
                  await this.onEdit(template.id, values);
                  this.formEditRef.current?.resetFields();
                } catch (error) {
                  console.log("校验失败:", error);
                }
              }}
            >
              <Form
                ref={this.formEditRef}
                layout="vertical"
                name="editOrgTemplate"
              >
                <Form.Item
                  name="name"
                  label="组织模板名称"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的组织模板名称!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="discription"
                  label="描述"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的描述!",
                    },
                  ]}
                >
                  <Input type="textarea" />
                </Form.Item>
                <Form.Item
                  name="type"
                  label="类型"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的类型!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={0}>公司</Radio>
                    <Radio value={1}>项目</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* <Form.Item
                  name="style"
                  label="style"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的style!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={0}>0</Radio>
                    <Radio value={1}>1</Radio>
                  </Radio.Group>
                </Form.Item> */}
              </Form>
            </Modal>
            {/* 组织模板应用管理 */}
            <Modal
              title="组织模板应用管理"
              visible={this.state.visibleApplication}
              onOk={this.handleApplicationOk}
              onCancel={this.handleApplicationCancel}
              forceRender
            >
              {/* 匹配选中 */}
              {this.state.systemAllApplicationLists.map((res: any) => (
                <p key={res.id}>
                  <Checkbox
                    onChange={(e) => this.onChange(e, id, res.id)}
                    checked={
                      // 匹配id是否相同
                      apps.findIndex((r: any) => r.id === res.id) !== -1
                        ? true
                        : false
                    }
                  >
                    {`${res.code} [${res.name}]`}
                  </Checkbox>
                </p>
              ))}
            </Modal>
          </Card>
        </div>
      </div>
    );
  }
}

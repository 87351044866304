import React from "react";
import { InitSpin } from "@/common";
import LicenseRouter from "../router/my.router";
import SettingRouter from "../router/setting.router";
const routes: Array<any> = [
  {
    name: "我的",
    path: "/my",
    exact: true,
    permission: true,
    single: true,
    com: LicenseRouter,
  },
  {
    name: "设置",
    path: "/setting",
    single: true,
    exact: true,
    permission: true,
    com: SettingRouter,
  },
];

export default routes;

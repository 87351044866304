import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
} from "antd";
import axios from "@/utils/http";
import PermissionCard from "./roleTemplateCard.com";
import { FormInstance } from "antd/lib/form";

interface IProps {}
interface IState {
  visible: boolean; // 弹窗开关
  systemAllRoleTemplateLists: any[]; // 角色模板列表
}

export default class RoleTemplate extends Component<IProps, IState> {
  private formRef = React.createRef<FormInstance>();

  public state: IState = {
    visible: false,
    systemAllRoleTemplateLists: [],
  };

  /**
   * @desc 创建角色模板
   */
  public onCreate = async (values: any) => {
    try {
      const res = await axios.post(
        "/api/account/v1/role/system/role-template",
        values
      );
      console.log("创建角色模板成功", res.data.data);
      // 获取一遍
      this.onGetAllRoleTemplateLists();
      this.setState({
        visible: false,
      });
    } catch (error) {
      console.log("创建角色模板失败", error.response);
    }
  };

  /**
   * @desc 打开创建弹窗
   */
  public open = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * @desc 关闭创建弹窗
   */
  public close = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * @desc 获取所有角色模板
   */
  public onGetAllRoleTemplateLists = async () => {
    try {
      const res = await axios.get(
        "/api/account/v1/role/system/role-teamplet/all"
      );
      console.log("创建角色模板成功", res.data.data);
      this.setState({
        systemAllRoleTemplateLists: res.data.data,
      });
    } catch (error) {
      console.log("创建角色模板错误", error.response);
    }
  };

  public componentDidMount() {
    this.onGetAllRoleTemplateLists();
  }
  public render(): ReactNode {
    return (
      <div>
        <div>
          <div style={{ overflow: "hidden" }}>
            <Button
              type="primary"
              onClick={this.open}
              style={{ float: "right" }}
            >
              创建角色模板
            </Button>
          </div>

          <Modal
            visible={this.state.visible}
            title="创建角色模板"
            okText="提交"
            cancelText="取消"
            onCancel={this.close}
            forceRender
            onOk={async () => {
              try {
                const values = await this.formRef.current?.validateFields();
                console.log(values);
                await this.onCreate(values);
                this.formRef.current?.resetFields();
              } catch (error) {
                console.log("校验失败:", error);
              }
            }}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              name="permission"
              initialValues={{ type: 1, isUniqueInOrg: false }}
            >
              <Form.Item
                name="name"
                label="角色模板名称"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的角色模板名称!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="type"
                label="类型"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的类型!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0} disabled>
                    系统角色
                  </Radio>
                  <Radio value={1}>管理角色</Radio>
                  <Radio value={2} disabled>
                    应用角色
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="isUniqueInOrg"
                label="是否组织唯一"
                rules={[
                  {
                    required: true,
                    message: "请输入合理的是否组织唯一!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Modal>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.systemAllRoleTemplateLists.map((roleTemplate: any) => (
            <PermissionCard
              key={roleTemplate.id}
              roleTemplate={roleTemplate}
              update={this.onGetAllRoleTemplateLists}
            />
          ))}
        </div>
      </div>
    );
  }
}

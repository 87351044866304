import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Tabs,
  Space,
  Select,
} from "antd";
import style from "./my.module.less";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";
import { UserOutlined, TableOutlined } from "@ant-design/icons";
import { Typography } from "antd";
const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;

export interface IProps {}

export interface IState {
  visible: boolean; // 弹窗开关
  visibleEdit: boolean; // 弹窗开关
  systemMyCompanyLists: any[]; // 我的公司
  systemAllCompanyLists: any[]; // 公司列表
  systemAllOrgTemplateLists: [];
}

export default class AppIndex extends Component<IProps, IState> {
  private formRef = React.createRef<FormInstance>();
  private formEditRef = React.createRef<FormInstance>();

  public state: IState = {
    visible: false,
    visibleEdit: false,
    systemMyCompanyLists: [],
    systemAllCompanyLists: [],
    systemAllOrgTemplateLists: [],
  };

  /**
   * @desc 创建顶级组织
   */
  public onCreate = async (values: any) => {
    try {
      const res = await axios.post("/api/account/v1/org/system/org", values);
      console.log("创建顶级组织成功", res.data.data);
      // 获取一遍 ???
      this.onSystemGetMyCompanies();
      this.onSystemGetAllCompanies();
      this.setState({
        visible: false,
      });
    } catch (error) {
      console.log("创建顶级组织失败", error.response);
    }
  };

  /**
   * @desc 打开创建弹窗
   */
  public open = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * @desc 关闭创建弹窗
   */
  public close = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * @desc 打开编辑弹窗
   */
  public openEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  /**
   * @desc 关闭编辑弹窗
   */
  public closeEdit = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  /**
   * @desc 禁用公司
   */
  public onDisableCompany = async (orgId: string) => {
    try {
      const res = await axios.put(
        `/api/account/v1/org/system/${orgId}/disable`
      );
      console.log("禁用这个公司成功", res.data.data);
      message.success("成功");
      // 获取一遍 ???
      this.onSystemGetMyCompanies();
      this.onSystemGetAllCompanies();
    } catch (error) {
      console.log("禁用这个公司错误", error.response);
    }
  };

  /**
   * @desc 获取公司列表
   */
  public onSystemGetAllCompanies = async () => {
    try {
      const res = await axios.get("/api/account/v1/org/system/all");
      console.log("所有公司列表成功", res.data.data);
      this.setState({
        systemAllCompanyLists: res.data.data,
      });
    } catch (error) {
      console.log("所有公司列表错误", error.response);
    }
  };

  /**
   * @desc 获取我负责的公司
   */
  public onSystemGetMyCompanies = async () => {
    try {
      const res = await axios.get("/api/account/v1/org/system/my");
      console.log("我的公司列表成功", res.data.data);
      this.setState({
        systemMyCompanyLists: res.data.data,
      });
    } catch (error) {
      console.log("我的公司列表错误", error.response);
    }
  };

  orgId: string = "";
  /**
   * @desc 获取我负责的公司
   */
  public onEditCompany = async (orgId: string) => {
    this.openEdit();
    this.orgId = orgId;
    try {
      const res = await axios.get(`/api/account/v1/org/${orgId}/info`);
      console.log("获取单个成功", res.data.data);
      this.formEditRef.current?.setFieldsValue({
        name: res.data.data.name,
        discription: res.data.data.discription,
      });
    } catch (error) {
      console.log("获取单个错误", error.response);
    }
  };

  /**
   * @desc 获取我负责的公司
   */
  public onEdit = async (values: any) => {
    const orgId = this.orgId;
    try {
      const res = await axios.put(`/api/account/v1/org/${orgId}/info`, values);
      console.log("获取单个成功", res.data.data);
      this.orgId = "";
      await this.onSystemGetMyCompanies();
      await this.onSystemGetAllCompanies();
      this.closeEdit();
    } catch (error) {
      this.orgId = "";
      console.log("获取单个错误", error.response);
    }
  };

  /**
   * @desc 获取组织模板列表
   */
  public onGetAllOrgTemplate = async () => {
    try {
      const res = await axios.get("/api/account/v1/org-template/system");
      console.log("所有组织模板成功", res.data.data);
      this.setState({
        systemAllOrgTemplateLists: res.data.data,
      });
    } catch (error) {
      console.log("所有公司列表错误", error.response);
    }
  };

  public componentDidMount() {
    this.onSystemGetMyCompanies();
    this.onSystemGetAllCompanies();
    this.onGetAllOrgTemplate();
  }

  public render(): ReactNode {
    const stateList = ["正常", "已归档", "已禁用", "已删除"];
    const findTemplateName = (templateId: string) => {
      const template: any = this.state.systemAllOrgTemplateLists.find(
        (item: any) => item.id === templateId
      );
      if (template) {
        return template.name;
      }
    };
    return (
      <div className={style.appPanel_layout_container}>
        <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
          <TabPane
            tab={
              <span>
                <UserOutlined />
                我的
              </span>
            }
            key="1"
          >
            <div style={{ overflow: "hidden" }}>
              <Button
                type="primary"
                onClick={this.open}
                style={{ float: "right" }}
              >
                创建顶级组织
              </Button>
            </div>

            <Modal
              visible={this.state.visible}
              title="创建顶级组织"
              okText="提交"
              cancelText="取消"
              onCancel={this.close}
              forceRender
              onOk={() => {
                // 官网例子不好
                this.formRef.current
                  ?.validateFields()
                  .then((values: any) => {
                    this.onCreate(values);
                    this.formRef.current?.resetFields();
                  })
                  .catch((info: any) => {
                    console.log("校验失败:", info);
                  });
              }}
            >
              <Form
                ref={this.formRef}
                layout="vertical"
                name="createOrg"
                initialValues={{
                  type: 0,
                }}
              >
                <Form.Item
                  name="name"
                  label="组织名"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的组织名!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="discription"
                  label="描述"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的描述!",
                    },
                  ]}
                >
                  <Input type="textarea" />
                </Form.Item>
                <Form.Item
                  name="templateId"
                  label="模板"
                  rules={[
                    {
                      required: true,
                      message: "请选择合理的模板!",
                    },
                  ]}
                >
                  <Select placeholder="请选择模板！">
                    {this.state.systemAllOrgTemplateLists.map(
                      (template: any) => (
                        <Option value={template.id} key={template.id}>
                          {template.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Form>
            </Modal>

            {/* 修改组织 */}
            <Modal
              visible={this.state.visibleEdit}
              title="创建顶级组织"
              okText="提交"
              cancelText="取消"
              onCancel={this.closeEdit}
              forceRender
              onOk={() => {
                // 官网例子不好
                this.formEditRef.current
                  ?.validateFields()
                  .then((values: any) => {
                    this.onEdit(values);
                    this.formRef.current?.resetFields();
                  })
                  .catch((info: any) => {
                    console.log("校验失败:", info);
                  });
              }}
            >
              <Form ref={this.formEditRef} layout="vertical" name="editOrg">
                <Form.Item
                  name="name"
                  label="组织名"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的组织名!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="discription"
                  label="描述"
                  rules={[
                    {
                      required: true,
                      message: "请输入合理的描述!",
                    },
                  ]}
                >
                  <Input type="textarea" />
                </Form.Item>
              </Form>
            </Modal>

            <div className={style.appPanel_list_container}>
              {this.state.systemMyCompanyLists.map((company: any) => (
                <Card
                  style={{ width: "250px", margin: "16px" }}
                  key={company.id}
                  hoverable
                >
                  <p>
                    <Text strong>名称: </Text>
                    {company.name}
                  </p>
                  <p
                    style={{
                      width: "200px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={company.discription}
                  >
                    <Text strong>描述: </Text>
                    {company.discription}
                  </p>
                  <p>
                    <Text strong>类型: </Text>
                    {company.type === 0 && "公司"}
                    {company.type === 1 && "项目"}
                  </p>
                  <p>
                    <Text strong>模板: </Text>
                    {findTemplateName(company.templateId)}
                  </p>
                  <p style={(company.state !== 0 && { color: "red" }) || {}}>
                    <Text strong>状态: </Text>
                    {stateList[company.state]}
                  </p>
                  {/* {company.state === 0 && <p>状态: 正常状态</p>} */}
                  {/* {company.state === 1 && <p>状态: 已归档</p>} */}
                  {/* {company.state === 2 && <p>状态: 已禁用</p>} */}
                  {/* {company.state === 3 && <p>状态: 已删除</p>} */}

                  <Space size="middle">
                    <Button
                      type="link"
                      onClick={() => this.onEditCompany(company.id)}
                    >
                      修改
                    </Button>

                    {company.state === 0 && (
                      <Popconfirm
                        title="禁用这个公司?"
                        onConfirm={() => this.onDisableCompany(company.id)}
                        okText="确定"
                        cancelText="取消"
                      >
                        <a href="#">禁用</a>
                      </Popconfirm>
                    )}
                  </Space>
                </Card>
              ))}
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <TableOutlined />
                全部
              </span>
            }
            key="2"
          >
            {/* <PageHeader
              className={style.appPanel_layout_header}
              title="公司列表"
              subTitle=""
            /> */}
            <div className={style.appPanel_list_container}>
              {this.state.systemAllCompanyLists.map((company: any) => (
                <Card
                  style={{ width: "250px", margin: "16px" }}
                  key={company.id + 1}
                  hoverable
                >
                  <p>
                    <Text strong>名称: </Text>
                    {company.name}
                  </p>
                  <p
                    style={{
                      width: "200px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={company.discription}
                  >
                    <Text strong>描述: </Text>
                    {company.discription}
                  </p>
                  <p>
                    <Text strong>类型: </Text>
                    {company.type === 0 && "项目"}
                    {company.type === 1 && "公司"}
                  </p>
                  <p style={(company.state !== 0 && { color: "red" }) || {}}>
                    <Text strong>状态: </Text>
                    {stateList[company.state]}{" "}
                  </p>
                  {/* {company.state === 0 && <p>状态: 正常状态</p>}
                  {company.state === 1 && <p>状态: 已归档</p>}
                  {company.state === 2 && <p>状态: 已禁用</p>}
                  {company.state === 3 && <p>状态: 已删除</p>} */}
                </Card>
              ))}
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

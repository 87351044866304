import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Space,
} from "antd";
import { FormInstance } from "antd/lib/form";
import axios from "@/utils/http";
import { Typography } from "antd";
const { Text } = Typography;

interface IProps {
  permission: any;
  update: any;
}

interface IState {
  visibleEdit: boolean; // 修改权限
  visibleRouter: boolean; // 权限路由
  systemAllPermissionRouter: any[]; // 权限路由列表
}

export default class PermissionCard extends Component<IProps, IState> {
  private formEditRef = React.createRef<FormInstance>();
  private formRouterRef = React.createRef<FormInstance>();

  public state: IState = {
    visibleEdit: false,
    visibleRouter: false,
    systemAllPermissionRouter: [],
  };

  /**
   * @desc 打开路由弹窗
   */
  public openRouter = () => {
    this.setState({
      visibleRouter: true,
    });
  };

  /**
   * @desc 关闭由弹窗
   */
  public closeRouter = () => {
    this.setState({
      visibleRouter: false,
    });
  };

  /**
   * @desc 关闭路由弹窗
   */
  public handleRouterOk = () => {
    this.setState({
      visibleRouter: false,
    });
  };

  /**
   * @desc 关闭路由弹窗
   */
  public handleRouterCancel = () => {
    this.setState({
      visibleRouter: false,
    });
  };

  /**
   * @desc 打开编辑弹窗
   */
  public openEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  /**
   * @desc 关闭编辑弹窗
   */
  public closeEdit = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  /**
   * @desc 编辑权限提交
   */
  public onEdit = async (permissionId: string, values: any) => {
    console.log(values);

    try {
      const res = await axios.put(
        `/api/account/v1/permission/system/${permissionId}`,
        values
      );
      console.log("修改权限成功", res.data.data);
      // 关闭弹窗
      this.setState({
        visibleEdit: false,
      });
      // 获取一遍
      this.props.update();
    } catch (error) {
      console.log("修改权限失败", error.response);
      throw new Error("修改权限失败");
    }
  };

  /**
   * @desc 编辑权限初始化
   */
  public onEditPermission = async (permissionId: string) => {
    try {
      const res = await axios.get(
        `/api/account/v1/permission/system/${permissionId}/router`
      );
      this.formEditRef.current?.setFieldsValue({
        code: res.data.data.code,
        name: res.data.data.name,
      });
      this.openEdit();
      console.log("编辑权限成功", res.data.data);
    } catch (error) {
      console.log("编辑权限错误", error.response);
    }
  };

  /**
   * @desc 删除权限
   */
  public onDeletePermission = async (permissionId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/permission/system/${permissionId}`
      );
      this.props.update();
      console.log("删除权限成功", res.data.data);
    } catch (error) {
      console.log("删除权限错误", error.response);
    }
  };

  /**
   * @desc 获取路由列表
   */
  public onGetRouter = async () => {
    const { id: permissionId } = this.props.permission;
    try {
      const res = await axios.get(
        `/api/account/v1/permission/system/${permissionId}/router`
      );
      console.log("所有路由成功", res.data.data);
      this.setState({
        systemAllPermissionRouter: res.data.data.routers,
      });
    } catch (error) {
      console.log("所有路由错误", error.response);
    }
  };

  /**
   * @desc 删除权限路由
   */
  public onDeletePermissionRouter = async (routerId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/permission/system/router/${routerId}`
      );
      console.log("删除路由成功", res.data.data);
      this.onGetRouter();
    } catch (error) {
      console.log("删除路由错误", error.response);
    }
  };

  /**
   * @desc 权限路由弹窗初始化
   */
  public onManagePermissionRouter = async () => {
    await this.onGetRouter();
    this.openRouter();
  };

  /**
   * @desc 添加权限路由
   */
  public onFinish = async (values: any) => {
    const { id: permissionId } = this.props.permission;
    const newValue = values;
    newValue.permissionId = permissionId;
    try {
      const res = await axios.post(
        `/api/account/v1/permission/system/router`,
        newValue
      );
      console.log("添加路由成功", res.data.data);
      this.onGetRouter();
    } catch (error) {
      console.log("添加路由错误", error.response);
    }
  };

  public componentDidMount() {}

  public render(): ReactNode {
    const { id, code, name, routers } = this.props.permission;
    return (
      <Card style={{ width: "300px", margin: "16px" }} hoverable>
        {/* <p>id:{id}</p> */}
        <p>
          <Text strong>权限编码: </Text>
          {code}
        </p>
        <p>
          <Text strong>权限名称: </Text>
          {name}
        </p>
        {/* <p>routers:{JSON.stringify(routers)}</p> */}

        <Space>
          <Button type="link" onClick={() => this.onEditPermission(id)}>
            修改
          </Button>

          <Button
            type="link"
            onClick={() => this.onManagePermissionRouter()}
            style={{ marginRight: "16px" }}
          >
            路由
          </Button>

          <Popconfirm
            title="删除这个权限?"
            onConfirm={() => this.onDeletePermission(id)}
            okText="确定"
            cancelText="取消"
          >
            <a href="#">删除</a>
          </Popconfirm>
        </Space>
        {/* 修改 */}
        <Modal
          visible={this.state.visibleEdit}
          title="修改权限"
          okText="提交"
          cancelText="取消"
          onCancel={this.closeEdit}
          forceRender
          onOk={async () => {
            try {
              const values = await this.formEditRef.current?.validateFields();
              await this.onEdit(id, values);
              this.formEditRef.current?.resetFields();
            } catch (error) {
              console.log("校验失败:", error);
            }
          }}
        >
          <Form ref={this.formEditRef} layout="vertical" name="editPermission">
            <Form.Item
              name="code"
              label="权限编码"
              rules={[
                {
                  required: true,
                  message: "请输入合理的权限编码!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="权限名称"
              rules={[
                {
                  required: true,
                  message: "请输入合理的权限名称!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        {/* 管理权限路由 */}
        <Modal
          title="管理权限路由"
          visible={this.state.visibleRouter}
          onOk={this.handleRouterOk}
          onCancel={this.handleRouterCancel}
          footer={null}
          maskClosable={false}
          forceRender
        >
          {this.state.systemAllPermissionRouter &&
            this.state.systemAllPermissionRouter.map(
              (res: any, index: number) => (
                <div
                  key={res.id}
                  style={{
                    marginBottom: "16px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "12px",
                    padding: "12px",
                  }}
                >
                  {/* <p>id:{res.id}</p> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "middle",
                    }}
                  >
                    <p>method: {res.method}</p>
                    <Button
                      type="link"
                      onClick={() => this.onDeletePermissionRouter(res.id)}
                    >
                      删除
                    </Button>
                  </div>
                  <p
                    style={{
                      width: "250px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={res.url}
                  >
                    url: {res.url}
                  </p>
                </div>
              )
            )}

          <Form
            ref={this.formRouterRef}
            name="control-ref"
            onFinish={this.onFinish}
          >
            <Form.Item
              name="method"
              label="method"
              rules={[{ required: true, message: "请输入合理的method!" }]}
            >
              <Radio.Group>
                <Radio value={"GET"}>GET</Radio>
                <Radio value={"POST"}>POST</Radio>
                <Radio value={"PUT"}>PUT</Radio>
                <Radio value={"DELETE"}>DELETE</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="url"
              label="url"
              rules={[{ required: true, message: "请输入合理的url!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

import React, { Component, ReactNode } from "react";
import {
  PageHeader,
  Divider,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Popconfirm,
  message,
  Checkbox,
  Row,
  Col,
  Space,
} from "antd";
import axios from "@/utils/http";
import { FormInstance } from "antd/lib/form";
import { Typography } from "antd";

const { Text } = Typography;
interface IProps {
  roleTemplate: any;
  update: any;
}
interface IState {
  visibleEdit: boolean; // 修改模板
  visiblePermission: boolean; //角色权限
  systemAllPermissionCheckbox: any[]; // 所有权限
}

export default class RoleTemplateCard extends Component<IProps, IState> {
  private formEditRef = React.createRef<FormInstance>();

  public state: IState = {
    visibleEdit: false,
    visiblePermission: false,
    systemAllPermissionCheckbox: [],
  };

  /**
   * @desc 打开权限弹窗
   */
  public showPermissionModal = () => {
    this.setState({
      visiblePermission: true,
    });
  };

  /**
   * @desc 关闭权限弹窗
   */
  public handlePermissionOk = () => {
    this.setState({
      visiblePermission: false,
    });
  };

  /**
   * @desc 关闭权限弹窗
   */
  public handlePermissionCancel = () => {
    this.setState({
      visiblePermission: false,
    });
  };

  /**
   * @desc 打开编辑弹窗
   */
  public openEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  /**
   * @desc 关闭编辑弹窗
   */
  public closeEdit = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  /**
   * @desc 修改角色模板
   */
  public onEditSubmit = async (templateId: string, values: any) => {
    console.log(values);
    try {
      const res = await axios.put(
        `/api/account/v1/role/system/role-template/${templateId}`,
        values
      );
      console.log("修改角色模板成功", res.data.data);
      // 关闭弹窗
      this.setState({
        visibleEdit: false,
      });
      // 获取一遍
      this.props.update();
    } catch (error) {
      console.log("修改角色模板失败", error.response);
      throw new Error("修改角色模板失败");
    }
  };

  /**
   * @desc 获取单个角色模板初始化
   */
  public onEditRoleTemplate = async (templateId: string) => {
    try {
      const res = await axios.get(
        `/api/account/v1/role/system/role-teamplet/i/${templateId}`
      );
      this.formEditRef.current?.setFieldsValue({
        name: res.data.data.name,
        type: res.data.data.type,
        isUniqueInOrg: res.data.data.isUniqueInOrg,
      });
      this.openEdit();
      console.log("编辑权限成功", res.data.data);
    } catch (error) {
      console.log("编辑权限错误", error.response);
    }
  };

  /**
   * @desc 删除单个角色模板
   */
  public onDeleteRoleTemplate = async (templateId: string) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/role/system/role-template/${templateId}`
      );
      this.props.update();
      console.log("删除单个角色模板成功", res.data.data);
    } catch (error) {
      console.log("删除单个角色模板错误", error.response);
    }
  };

  /**
   * @desc 打开弹窗初始化
   */
  public onManagePermission = async () => {
    await this.onGetAllPermissionLists();
    await this.showPermissionModal();
  };

  /**
   * @desc 获取所有权限
   */
  public onGetAllPermissionLists = async () => {
    try {
      const res = await axios.get("/api/account/v1/permission/system/all");
      console.log("所有权限成功", res.data.data);
      const data = res.data.data.map((res: any) => {
        return {
          id: res.id,
          code: res.code,
          name: res.name,
        };
      });
      console.log("data", data);
      this.setState({
        systemAllPermissionCheckbox: data,
      });
    } catch (error) {
      console.log("所有权限错误", error.response);
    }
  };

  /**
   * @desc 点击按钮添加/删除应用
   */
  public onChange = (e: any, templateId: any, permissionId: any) => {
    console.log(`checked = ${e.target.checked}`);
    console.log(templateId, permissionId);
    if (e.target.checked === false) {
      this.onRemovePermission(templateId, permissionId);
    } else {
      this.onAddPermission(templateId, permissionId);
    }
  };

  /**
   * @desc 删除权限
   */
  public onRemovePermission = async (
    templateId: string,
    permissionId: string
  ) => {
    try {
      const res = await axios.delete(
        `/api/account/v1/role/system/role-template/${templateId}/permission/${permissionId}`
      );
      this.props.update();
      console.log("移除权限成功", res.data.data);
    } catch (error) {
      console.log("移除权限错误", error.response);
    }
  };

  /**
   * @desc 添加权限
   */
  public onAddPermission = async (templateId: string, permissionId: string) => {
    const values = { templateId, permissionId };
    try {
      const res = await axios.post(
        `/api/account/v1/role/system/role-template/permission`,
        values
      );
      this.props.update();
      console.log("添加权限成功", res.data.data);
    } catch (error) {
      console.log("添加权限错误", error.response);
    }
  };

  public componentDidMount() {}

  public render(): ReactNode {
    const {
      id,
      name,
      type,
      isUniqueInOrg,
      permissions,
    } = this.props.roleTemplate;
    return (
      <Card style={{ width: "300px", margin: "16px" }} hoverable>
        {/* <p>id:{id}</p> */}

        <p>
          <Text strong>模板名称: </Text>
          {name}
        </p>

        {type === 0 && (
          <p>
            <Text strong>模板类型: </Text>系统角色
          </p>
        )}
        {type === 1 && (
          <p>
            <Text strong>模板类型: </Text>管理角色
          </p>
        )}
        {type === 2 && (
          <p>
            <Text strong>模板类型: </Text>应用角色
          </p>
        )}
        <p style={(isUniqueInOrg !== false && { color: "red" }) || {}}>
          <Text strong>组织唯一: </Text>
          {isUniqueInOrg === true ? "是" : "否"}
        </p>
        {/* <p>permissions:{JSON.stringify(permissions)}</p> */}

        <Space>
          <Button type="link" onClick={() => this.onEditRoleTemplate(id)}>
            修改
          </Button>

          <Button
            type="link"
            onClick={() => this.onManagePermission()}
            style={{ marginRight: "16px" }}
          >
            权限
          </Button>

          <Popconfirm
            title="删除这个角色模板?"
            onConfirm={() => this.onDeleteRoleTemplate(id)}
            okText="确定"
            cancelText="取消"
          >
            <a href="#">删除</a>
          </Popconfirm>
        </Space>
        {/* 修改 */}
        <Modal
          visible={this.state.visibleEdit}
          title="修改角色模板"
          okText="提交"
          cancelText="取消"
          onCancel={this.closeEdit}
          forceRender
          onOk={async () => {
            try {
              const values = await this.formEditRef.current?.validateFields();
              await this.onEditSubmit(id, values);
              this.formEditRef.current?.resetFields();
            } catch (error) {
              console.log("校验失败:", error);
            }
          }}
        >
          <Form
            ref={this.formEditRef}
            layout="vertical"
            name="editRoleTemplate"
            id={"editRoleTemplate" + id}
          >
            <Form.Item
              name="name"
              label="角色模板名称"
              rules={[
                {
                  required: true,
                  message: "请输入合理的角色模板名称!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="type"
              label="类型"
              rules={[
                {
                  required: true,
                  message: "请输入合理的类型!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={0} disabled>
                  系统角色
                </Radio>
                <Radio value={1}>管理角色</Radio>
                <Radio value={2} disabled>
                  应用角色
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="isUniqueInOrg"
              label="是否组织唯一"
              rules={[
                {
                  required: true,
                  message: "请输入合理的是否组织唯一!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
        {/* 增减权限 */}
        <Modal
          title="管理角色模板权限"
          visible={this.state.visiblePermission}
          onOk={this.handlePermissionOk}
          onCancel={this.handlePermissionCancel}
          forceRender
        >
          {this.state.systemAllPermissionCheckbox.map((res: any) => (
            <p key={res.id}>
              <Checkbox
                onChange={(e) => this.onChange(e, id, res.id)}
                checked={
                  permissions.findIndex((r: any) => r.id === res.id) !== -1
                    ? true
                    : false
                }
              >
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "16px",
                    display: "inline-block",
                  }}
                >
                  <p
                    style={{
                      width: "120px",
                      fontSize: "14px",
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      lineHeight: "14px",
                      padding: 0,
                      margin: 0,
                    }}
                    title={res.name}
                  >
                    {`${res.name}`}
                  </p>

                  <p
                    style={{
                      width: "100px",
                      fontSize: "14px",
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      lineHeight: "14px",
                      padding: 0,
                      margin: 0,
                    }}
                    title={res.code}
                  >
                    {res.code}
                  </p>
                </p>
              </Checkbox>
            </p>
          ))}
        </Modal>
      </Card>
    );
  }
}

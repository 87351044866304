import React from "react";
import "./App.less";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { PMSLayout } from "@/common";
import bizRoutes from "@/config/bizRouter.config"; //业务组件引入
import { PrivateRoute } from "./utils/privateRoutes";
import LoginRouter from "./router/login.router";
import Error404Router from "./router/error404.router";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {/* 登录 */}
          <Route
            exact
            path="/login"
            sensitive={false}
            component={LoginRouter}
          />
          {/* <PMSLayout> */}
          {bizRoutes.map((item, index) => (
            <PrivateRoute
              key={index}
              permission={item.permission}
              exact={item.exact}
              path={item.path}
              sensitive={false}
              component={item.com}
            />
          ))}

          <Route exact path="/" render={() => <Redirect to="/my" push />} />
          {/* <Route
              exact
              path="/my"
              sensitive={false}
              component={LicenseRouter}
            />
            <Route
              exact
              path="/setting"
              sensitive={false}
              component={SettingRouter}
            /> */}
          {/* </PMSLayout> */}

          <Route component={Error404Router} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
